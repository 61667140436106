import React from "react";
import LogoRaw from "../../../images/Logo.svg?react";
import OutlineIconLogoRaw from "../../../images/OutlineIconLogo.svg?react";

type Props = {
  fill: string;
  height?: number | string;
};

/**
 * The main app logo.
 */
export const Logo: React.FC<Props> = ({ fill, height = 24 }) => {
  return <LogoRaw fill={fill} height={height} />;
};

/**
 * Outline and icon only logo.
 */
export const OutlineIconLogo: React.FC<Props> = ({ fill, height }) => {
  return <OutlineIconLogoRaw fill={fill} height={height} />;
};
