import mixpanel from "mixpanel-browser";
import { config } from "../config";
import { TrackedEvent } from "./events";
import { isDevelopment } from "../environment";
import { constNull } from "fp-ts/lib/function";

type IdentifyUserParams = {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
};

type Tracker = {
  initTracker: () => void;
  identifyUser: (identity: IdentifyUserParams) => void;
  deIdentifyUser: () => void;
  trackEvent: (evt: TrackedEvent) => void;
};

/**
 * Initialize the event tracker.
 */
const initTracker = () => {
  mixpanel.init(config.trackerToken, {
    api_host: "https://api-eu.mixpanel.com",
  });
};

/**
 * Identify a user on the tracking platform.
 */
const identifyUser = (params: IdentifyUserParams) => {
  const { userId, email, firstName, lastName } = params;

  mixpanel.identify(userId);

  mixpanel.people.set({
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
  });
};

/**
 * Remove all the super-properties applied with the `identifyUser` method.
 */
const deIdentifyUser = () => {
  mixpanel.reset();
};

/**
 * Track a single event.
 */
const trackEvent = (evt: TrackedEvent) => {
  const name = evt.name;
  const payload = "payload" in evt ? evt.payload : undefined;

  mixpanel.track(name, payload);
};

/**
 * Production tracker implementation.
 */
const prodTracker: Tracker = {
  initTracker,
  identifyUser,
  deIdentifyUser,
  trackEvent,
};

/**
 * Development tracker implementation.
 */
const devTracker: Tracker = {
  initTracker: constNull,
  identifyUser: constNull,
  deIdentifyUser: constNull,
  trackEvent: constNull,
};

export const tracker = isDevelopment ? devTracker : prodTracker;
