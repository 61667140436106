import { useTheme } from "@mui/material";

/**
 * Retrieve the V2 palette from the current theme.
 * This palette represents the colors used in the Heritage V2 Design System.
 */
export function useHeritageV2Palette() {
  const {
    palette: { heritageV2: heritagePaletteV2 },
  } = useTheme();

  return heritagePaletteV2;
}
