import React, { ComponentProps, useMemo } from "react";
import { Button as BaseButton } from "@mui/material";
import { match, P } from "ts-pattern";
import { BaseIconPublicProps } from "../Icon/Icon";
import { DesignTokens } from "@heritageholdings/lib-commons-frontend";

type ButtonVariants = "primary" | "secondary" | "secondary-dark" | "mini-text";

type MergedButtonVariants = `${ButtonVariants}${"" | "-negative"}`;

type Props = {
  children: string;
  variant: ButtonVariants;
  onClick?: () => void;
  disabled?: boolean;
  negative?: boolean;
  LeftIcon?: React.FC<BaseIconPublicProps> | string;
  href?: string;
} & Pick<ComponentProps<typeof BaseButton>, "type">;

const isString = (x: unknown): x is string => typeof x === "string";

/**
 * The core button component.
 */
export const Button: React.FC<Props> = ({
  children,
  variant,
  negative,
  onClick,
  disabled,
  LeftIcon,
  type,
  href,
}) => {
  const computedClassed = useMemo(() => {
    const classes = [];

    if (variant === "secondary-dark") classes.push("variant-secondary-dark");

    return classes.join(" ");
  }, [variant]);

  const completeVariant: MergedButtonVariants = `${variant}${
    negative ? "-negative" : ""
  }`;

  const computedVariant = match<
    MergedButtonVariants,
    ComponentProps<typeof BaseButton>["variant"]
  >(completeVariant)
    .with("primary", () => "contained")
    .with("secondary", () => "outlined")
    .with("secondary-dark", () => "outlined")
    .with("mini-text", () => "text")
    .with("primary-negative", () => "negative")
    .with("secondary-negative", () => "outlined-negative")
    .with("secondary-dark-negative", () => "outlined-negative")
    .with("mini-text-negative", () => "text-negative")
    .otherwise(() => "contained");

  // If the LeftIcon is a string, then it's a path to an icon and should be render as image, otherwise is a LeftIcon
  const startIcon = match(LeftIcon)
    .with(P.when(isString), (icon) => (
      <img
        src={icon}
        alt="icon"
        width={DesignTokens.sizes.iconSmall}
        height={DesignTokens.sizes.iconSmall}
      />
    ))
    .with(P.not(P.nullish), (Icon) =>
      Icon ? <Icon variant="small" /> : undefined,
    )
    .otherwise(() => undefined);

  return (
    <BaseButton
      type={type}
      variant={computedVariant}
      className={computedClassed}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      href={href}
    >
      {children}
    </BaseButton>
  );
};
