import { getEnv } from "./env";

/**
 * Next-gen configuration.
 */
export const config = {
  /**
   * The email used by the user to get support.
   */
  supportEmail: getEnv("VITE_SUPPORT_EMAIL") || "tech@heritageholdings.co",

  /**
   * The server host Url.
   */
  serverBaseUrl: getEnv("VITE_SERVER_BASE_URL") || "/api/platform",

  /**
   * creator for the xls exports
   */
  workbookCreator: "Heritage Holdings",

  /**
   * The path added as a prefix for all the routes.
   * E.g. "/nextgen".
   */
  pathPrefix: getEnv("VITE_PATH_PREFIX") ?? "",

  /**
   * The URL to the legacy app.
   */
  legacyAppUrl: `${
    getEnv("VITE_LEGACY_APP_URL") || "https://www.heritageholdings.co"
  }`,

  /**
   * The URL to the next gen dashboard.
   */
  heritageDashboardUrl: `${
    getEnv("VITE_DASHBOARD_URL") || "https://app.heritageholdings.co/dashboard"
  }`,
  /**
   * The URL to the legacy portfolio page.
   */
  heritageLegacyPortfolioPage: `${
    getEnv("VITE_LEGACY_PORTFOLIO_URL") ||
    "https://www.heritageholdings.co/dashboard/my-portfolio"
  }`,

  /**
   * A path added as a prefix for all the assets.
   * This could be used as a temporary fix to handle assets
   * on a different host (E.g. `heritageholdings.co` vs `app.heritageholdings.co`).
   */
  assetsPrefix:
    getEnv("VITE_ASSETS_PREFIX") ?? "https://www.heritageholdings.co",

  /**
   * The path used by graphql client
   */
  graphQLUrlPath: `${
    getEnv("VITE_SERVER_BASE_URL") || "/api/platform"
  }/graphql`,

  /**
   * Whether or not to enable Datadog
   */
  ddEnabled: `${getEnv("VITE_DD_ENABLED")}`.toLowerCase() === "true" || false,

  /**
   * Datadog environment, used for Real User Monitoring
   */
  ddEnvironment: getEnv("VITE_DD_ENVIRONMENT") || "dev",

  // See https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/
  ddTracingOrigins:
    `${getEnv("VITE_DD_TRACING_ORIGINS")}`.split(",").map((_) => _.trim()) ||
    [],

  // the fallback is the token of the staging project see https://eu.mixpanel.com/project/2975444/app/settings#project/2975444
  trackerToken:
    getEnv("VITE_TRACKER_TOKEN") || "30d6e1b8f1609095c8ff02cf99c24ae6",

  /**
   * Authentication standalone app path prefix.
   */
  authPathPrefix: getEnv("VITE_AUTH_PATH_PREFIX") || "/auth",

  /**
   * Login page url.
   */
  loginPageUrl: getEnv("VITE_LOGIN_PAGE_URL") || "/auth/login",

  /**
   * Signup page url.
   */
  sinupPageUrl: getEnv("VITE_SIGNUP_PAGE_URL") || "/auth/signup",

  /**
   * Url to the landing page.
   */
  landingPageUrl:
    getEnv("VITE_LANDING_PAGE_URL") || "https://iconic.test:5173/landing-page",

  /**
   * FM Dashboard url.
   */
  fmDashboardUrl:
    getEnv("VITE_FM_DASHBOARD_URL") || "https://www.heritageholdings.co/fm",

  /**
   * Enable the new theme V2 by default.
   */
  themeV2DefaultEnabled:
    `${getEnv("VITE_THEME_V2_DEFAULT_ENABLED")}`.toLowerCase() === "true" ||
    false,
};
