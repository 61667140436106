import React from "react";
import { match } from "ts-pattern";
import AssetsSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/assets.dynamic.svg?react";
import AssetsMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/assets.dynamic.svg?react";
import AssetsLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/assets.dynamic.svg?react";
import PortfolioSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/portfolio.dynamic.svg?react";
import PortfolioMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/portfolio.dynamic.svg?react";
import PortfolioLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/portfolio.dynamic.svg?react";
import OpportunitiesSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/opportunities.dynamic.svg?react";
import OpportunitiesMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/opportunities.dynamic.svg?react";
import OpportunitiesLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/opportunities.dynamic.svg?react";
import CashflowSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/cashflow.dynamic.svg?react";
import CashflowMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/cashflow.dynamic.svg?react";
import CashflowLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/cashflow.dynamic.svg?react";
import DocumentsSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/dataRoom.dynamic.svg?react";
import DocumentsMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/dataRoom.dynamic.svg?react";
import DocumentsLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/dataRoom.dynamic.svg?react";
import TransactionsSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/transactions.dynamic.svg?react";
import TransactionsMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/transactions.dynamic.svg?react";
import TransactionsLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/transactions.dynamic.svg?react";
import MenuSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/menu.dynamic.svg?react";
import MenuMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/menu.dynamic.svg?react";
import MenuLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/menu.dynamic.svg?react";
import CloseSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/close.dynamic.svg?react";
import CloseMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/close.dynamic.svg?react";
import CloseLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/close.dynamic.svg?react";
import ChevronLeftSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/chevronLeft.dynamic.svg?react";
import ChevronLeftMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/chevronLeft.dynamic.svg?react";
import ChevronLeftLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/chevronLeft.dynamic.svg?react";
import ChevronRightSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/chevronRight.dynamic.svg?react";
import ChevronRightMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/chevronRight.dynamic.svg?react";
import ChevronRightLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/chevronRight.dynamic.svg?react";
import ChevronDownSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/chevronDown.dynamic.svg?react";
import ChevronDownMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/chevronDown.dynamic.svg?react";
import ChevronDownLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/chevronDown.dynamic.svg?react";
import ArrowUpSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/arrowUp.dynamic.svg?react";
import ArrowUpMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/arrowUp.dynamic.svg?react";
import ArrowUpLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/arrowUp.dynamic.svg?react";
import ArrowDownSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/arrowDown.dynamic.svg?react";
import ArrowDownMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/arrowDown.dynamic.svg?react";
import ArrowDownLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/arrowDown.dynamic.svg?react";
import ArrowLeftSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/16/arrowLeft.dynamic.svg?react";
import ArrowLeftMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/32/arrowLeft.dynamic.svg?react";
import ArrowLeftLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/direction/64/arrowLeft.dynamic.svg?react";
import ExternalSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/external.dynamic.svg?react";
import ExternalMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/external.dynamic.svg?react";
import ExternalLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/external.dynamic.svg?react";
import FilterSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/filter.dynamic.svg?react";
import FilterMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/filter.dynamic.svg?react";
import FilterLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/filter.dynamic.svg?react";
import FilterOpenedSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/filterOpened.dynamic.svg?react";
import FilterOpenedMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/filterOpened.dynamic.svg?react";
import FilterOpenedLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/filterOpened.dynamic.svg?react";
import NotificationSuccessSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/16/success.dynamic.svg?react";
import NotificationSuccessMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/32/success.dynamic.svg?react";
import NotificationSuccessLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/64/success.dynamic.svg?react";
import NotificationErrorSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/16/error.dynamic.svg?react";
import NotificationErrorMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/32/error.dynamic.svg?react";
import NotificationErrorLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/64/error.dynamic.svg?react";
import DownloadSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/download.dynamic.svg?react";
import DownloadMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/download.dynamic.svg?react";
import DownloadLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/download.dynamic.svg?react";
import DownloadTableSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/downloadCSV.dynamic.svg?react";
import DownloadTableMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/downloadCSV.dynamic.svg?react";
import DownloadTableLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/downloadCSV.dynamic.svg?react";
import ViewSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/view.dynamic.svg?react";
import ViewMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/view.dynamic.svg?react";
import ViewLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/download.dynamic.svg?react";
import CheckSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/check.dynamic.svg?react";
import CheckMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/check.dynamic.svg?react";
import CheckLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/check.dynamic.svg?react";
import CalendarSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/calendar.dynamic.svg?react";
import CalendarMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/calendar.dynamic.svg?react";
import CalendarLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/calendar.dynamic.svg?react";
import DeleteSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/delete.dynamic.svg?react";
import DeleteMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/delete.dynamic.svg?react";
import DeleteLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/delete.dynamic.svg?react";
import ConfigureSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/16/configure.dynamic.svg?react";
import ConfigureMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/32/configure.dynamic.svg?react";
import ConfigureLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/actions/64/configure.dynamic.svg?react";
import UserSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/16/user.dynamic.svg?react";
import UserMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/32/user.dynamic.svg?react";
import UserLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/navigation/64/user.dynamic.svg?react";
import WarningSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/16/warning.dynamic.svg?react";
import WarningMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/32/warning.dynamic.svg?react";
import WarningLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/notifications/64/warning.dynamic.svg?react";
import LockSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/lock.dynamic.svg?react";
import LockMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/lock.dynamic.svg?react";
import LockLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/lock.dynamic.svg?react";
import DetailsMinusSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/detailsMinus.dynamic.svg?react";
import DetailsMinusMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/detailsMinus.dynamic.svg?react";
import DetailsMinusLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/detailsMinus.dynamic.svg?react";
import DetailsPlusSmallRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/16/detailsPlus.dynamic.svg?react";
import DetailsPlusMediumRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/32/detailsPlus.dynamic.svg?react";
import DetailsPlusLargeRawIcon from "@heritageholdings/lib-commons-frontend/assets/icons/other/64/detailsPlus.dynamic.svg?react";

export type BaseIconPublicProps = {
  variant: "small" | "medium" | "large";
  fill?: string;
  stroke?: string;
  className?: string;
};

type BaseIconProps = BaseIconPublicProps & {
  IconSmall: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconMedium: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  IconLarge: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

/**
 * A base icon component with the style logic.
 */
const BaseIcon = (props: BaseIconProps) => {
  const Icon = match(props.variant)
    .with("small", () => props.IconSmall)
    .with("medium", () => props.IconMedium)
    .with("large", () => props.IconLarge)
    .exhaustive();

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      style={{
        fill: props.fill,
        color: props.stroke,
      }}
      className={props.className}
    />
  );
};

/**
 * The portfolio icon.
 */
export const PortfolioIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={PortfolioSmallRawIcon}
    IconMedium={PortfolioMediumRawIcon}
    IconLarge={PortfolioLargeRawIcon}
    {...props}
  />
);

/**
 * The opportunities icon.
 */
export const OpportunitiesIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={OpportunitiesSmallRawIcon}
    IconMedium={OpportunitiesMediumRawIcon}
    IconLarge={OpportunitiesLargeRawIcon}
    {...props}
  />
);

/**
 * The cashflow icon.
 */
export const CashflowIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={CashflowSmallRawIcon}
    IconMedium={CashflowMediumRawIcon}
    IconLarge={CashflowLargeRawIcon}
    {...props}
  />
);

/**
 * The documents icon.
 */
export const DocumentsIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DocumentsSmallRawIcon}
    IconMedium={DocumentsMediumRawIcon}
    IconLarge={DocumentsLargeRawIcon}
    {...props}
  />
);

/**
 * The transactions icon.
 */
export const TransactionsIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={TransactionsSmallRawIcon}
    IconMedium={TransactionsMediumRawIcon}
    IconLarge={TransactionsLargeRawIcon}
    {...props}
  />
);

/**
 * The assets icon.
 */
export const AssetsIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={AssetsSmallRawIcon}
    IconMedium={AssetsMediumRawIcon}
    IconLarge={AssetsLargeRawIcon}
    {...props}
  />
);

/**
 * The menu icon.
 */
export const MenuIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={MenuSmallRawIcon}
    IconMedium={MenuMediumRawIcon}
    IconLarge={MenuLargeRawIcon}
    {...props}
  />
);

/**
 * The close icon.
 */
export const CloseIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={CloseSmallRawIcon}
    IconMedium={CloseMediumRawIcon}
    IconLarge={CloseLargeRawIcon}
    {...props}
  />
);

/**
 * The left chevron icon.
 */
export const ChevronLeft = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ChevronLeftSmallRawIcon}
    IconMedium={ChevronLeftMediumRawIcon}
    IconLarge={ChevronLeftLargeRawIcon}
    {...props}
  />
);

/**
 * The right chevron icon.
 */
export const ChevronRight = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ChevronRightSmallRawIcon}
    IconMedium={ChevronRightMediumRawIcon}
    IconLarge={ChevronRightLargeRawIcon}
    {...props}
  />
);

/**
 * The down chevron icon.
 */
export const ChevronDown = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ChevronDownSmallRawIcon}
    IconMedium={ChevronDownMediumRawIcon}
    IconLarge={ChevronDownLargeRawIcon}
    {...props}
  />
);

/**
 * The arrow up icon.
 */
export const ArrowUp = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ArrowUpSmallRawIcon}
    IconMedium={ArrowUpMediumRawIcon}
    IconLarge={ArrowUpLargeRawIcon}
    {...props}
  />
);

/**
 * The arrow down icon.
 */
export const ArrowDown = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ArrowDownSmallRawIcon}
    IconMedium={ArrowDownMediumRawIcon}
    IconLarge={ArrowDownLargeRawIcon}
    {...props}
  />
);

/**
 * The arrow left icon.
 */
export const ArrowLeft = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ArrowLeftSmallRawIcon}
    IconMedium={ArrowLeftMediumRawIcon}
    IconLarge={ArrowLeftLargeRawIcon}
    {...props}
  />
);

/**
 * The external link icon.
 */
export const External = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ExternalSmallRawIcon}
    IconMedium={ExternalMediumRawIcon}
    IconLarge={ExternalLargeRawIcon}
    {...props}
  />
);

/**
 * The filter icon.
 */
export const Filter = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={FilterSmallRawIcon}
    IconMedium={FilterMediumRawIcon}
    IconLarge={FilterLargeRawIcon}
    {...props}
  />
);

/**
 * The opened filter icon.
 */
export const FilterOpened = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={FilterOpenedSmallRawIcon}
    IconMedium={FilterOpenedMediumRawIcon}
    IconLarge={FilterOpenedLargeRawIcon}
    {...props}
  />
);

/**
 * The notification success icon
 */
export const NotificationSuccess = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={NotificationSuccessSmallRawIcon}
    IconMedium={NotificationSuccessMediumRawIcon}
    IconLarge={NotificationSuccessLargeRawIcon}
    {...props}
  />
);

/**
 * The notification error icon
 */
export const NotificationError = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={NotificationErrorSmallRawIcon}
    IconMedium={NotificationErrorMediumRawIcon}
    IconLarge={NotificationErrorLargeRawIcon}
    {...props}
  />
);

/**
 * The download icon.
 */
export const DownloadIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DownloadSmallRawIcon}
    IconMedium={DownloadMediumRawIcon}
    IconLarge={DownloadLargeRawIcon}
    {...props}
  />
);

/**
 * The download table icon.
 */
export const DownloadTableIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DownloadTableSmallRawIcon}
    IconMedium={DownloadTableMediumRawIcon}
    IconLarge={DownloadTableLargeRawIcon}
    {...props}
  />
);

/**
 * The view icon (opposed as the download one).
 */
export const ViewIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ViewSmallRawIcon}
    IconMedium={ViewMediumRawIcon}
    IconLarge={ViewLargeRawIcon}
    {...props}
  />
);

/**
 * The check icon.
 */
export const CheckIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={CheckSmallRawIcon}
    IconMedium={CheckMediumRawIcon}
    IconLarge={CheckLargeRawIcon}
    {...props}
  />
);

/**
 * The calendar icon.
 */
export const CalendarIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={CalendarSmallRawIcon}
    IconMedium={CalendarMediumRawIcon}
    IconLarge={CalendarLargeRawIcon}
    {...props}
  />
);

/**
 * The delete icon.
 */
export const DeleteIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DeleteSmallRawIcon}
    IconMedium={DeleteMediumRawIcon}
    IconLarge={DeleteLargeRawIcon}
    {...props}
  />
);

/**
 * The configure icon.
 */
export const ConfigureIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={ConfigureSmallRawIcon}
    IconMedium={ConfigureMediumRawIcon}
    IconLarge={ConfigureLargeRawIcon}
    {...props}
  />
);

/**
 * The user icon.
 */
export const UserIcon = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={UserSmallRawIcon}
    IconMedium={UserMediumRawIcon}
    IconLarge={UserLargeRawIcon}
    {...props}
  />
);

/**
 * The warning icon.
 */
export const Warning = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={WarningSmallRawIcon}
    IconMedium={WarningMediumRawIcon}
    IconLarge={WarningLargeRawIcon}
    {...props}
  />
);

/**
 * The lock icon.
 */
export const Lock = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={LockSmallRawIcon}
    IconMedium={LockMediumRawIcon}
    IconLarge={LockLargeRawIcon}
    {...props}
  />
);

/**
 * The Details Plus icon.
 */
export const DetailsPlus = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DetailsPlusSmallRawIcon}
    IconMedium={DetailsPlusMediumRawIcon}
    IconLarge={DetailsPlusLargeRawIcon}
    {...props}
  />
);

/**
 * The Details Minus icon.
 */
export const DetailsMinus = (props: BaseIconPublicProps) => (
  <BaseIcon
    IconSmall={DetailsMinusSmallRawIcon}
    IconMedium={DetailsMinusMediumRawIcon}
    IconLarge={DetailsMinusLargeRawIcon}
    {...props}
  />
);
